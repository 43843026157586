import React from 'react';
import { Text, View } from 'react-native';
import { CustomIcon } from "../../General/CustomIcon";
import { getFont,  WHITE } from '../../../style';
import { StarRating } from '../Products/FertilizerRow'

export default function MeteoView(props) {
    return (
        <View style={{padding: 16, backgroundColor: props.meteo_response.bg, borderRadius: 8}}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <CustomIcon name={props.meteo_response.i} size={55} color={WHITE} />
              <View style={{flex: 1}}>
                <View style={{alignItems:'flex-end'}}>
                  <StarRating similarity_score={props.meteo_response.score} starColor={WHITE}/>
                </View>
                <Text style={{...getFont(16, WHITE), 
                  textAlign: 'right'}}>
                  {props.meteo_response.msg}
                </Text>
              </View>
            </View>
            <View style={{height: 1, backgroundColor: WHITE, marginVertical: 5}}/>
            <View style={{flexDirection: 'row', justifyContent:'space-between'}}>
              <Text style={{...getFont(13, WHITE)}}>
                {props.stateApi.response.name + " (" + props.stateApi.response.sys.country.toUpperCase() + ")"}
              </Text>
              <View style={{flexDirection: 'row', alignContent: 'flex-end'}}>
                  {/* <Ionicons name={"thermometer-outline"} size={18} color={WHITE} /> */}
                  <CustomIcon name={'settings'} color={WHITE} size={18}/>
                  <Text style={{...getFont(13, WHITE), textAlign: 'right'}}>
                    {props.stateApi.response.main.temp}° -
                  </Text>
                  {/* <Ionicons name={"water-outline"} size={18} color={WHITE} /> */}
                  <CustomIcon name={'settings'} color={WHITE} size={18}/>
                  <Text style={{...getFont(13, WHITE), textAlign: 'right'}}>
                    {props.stateApi.response.main.humidity}%
                  </Text>
              </View>
            </View>
        </View>
    )
}
import React, { useState, useEffect, useContext } from 'react';
import { Platform, View } from 'react-native';
import Geolocation from 'react-native-geolocation-service';
import { UserContext } from '../../../utils/context'
import { getMeteo } from '../../../api/dcm_api'
import { getStatusForMeteo } from './utilsResults'
import MeteoView from './MeteoView'


export default function Meteo() {

  const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: null});
  const { languages, code } = useContext(UserContext); 


  const getMeteoApi = (position) => {
    setStateApi({isLoading: true, error: null, response: null})
    getMeteo(position.coords.latitude, position.coords.longitude).then( s => {
      s.response ? 
        setStateApi({isLoading: false, error: null, response: s.response}) :
        setStateApi({isLoading: false, error: s.error, response: null})
    })
  }

  const getLocation = () => {
    Geolocation.getCurrentPosition(
      (position) => getMeteoApi(position),
      (error) => console.log({'code': error.code, 'message':error.message}),
      { 
        enableHighAccuracy: true, 
        timeout: 15000, 
        maximumAge: 10000 }
    );
  }

  

  useEffect(() => {
    if (Platform.OS === 'web') {
      getLocation()
    }
  }, []);

  const meteo_response = stateApi.response ? getStatusForMeteo(stateApi.response, languages, code) : null
  

  return (
    stateApi.response ? <MeteoView stateApi={stateApi} meteo_response={meteo_response}/> : <View></View>
  );
}
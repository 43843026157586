import React, { useEffect, useState, useContext, useRef }  from "react";
import { View, Text, ScrollView } from 'react-native';
import { BUTTON_SECONDARY, BUTTON_PRIMARY, MEDIUM_B, RED } from "../../../style";
import { ChoiceTextButton, CustomTextInput, LineView } from "../../General/Other";
import { UserContext } from '../../../utils/context'
import { DISTANCE, getUnity } from '../../../metrics'

export function Question(p) {
    
    const [internalReset, setInternalReset] = useState(p.isReset);
    
    return (
        <View>
            <View>
                <Text style={MEDIUM_B}>
                    {p.question}
                </Text>
                <View style={
                    {flexDirection: 'column', justifyContent: 'center', marginVertical: 12}}>
                    <ChoiceTextButton 
                        extra_style={{marginHorizontal: 10}}
                        type={ internalReset ? BUTTON_SECONDARY: (p.internalValue ? BUTTON_PRIMARY : BUTTON_SECONDARY)} 
                        title={p.answers[0].toUpperCase()} 
                        onPress={() => {
                            setInternalReset(false)
                            p.onResponse(true)}}/>
                    <ChoiceTextButton 
                        extra_style={{marginHorizontal: 10}}
                        type={ internalReset ? BUTTON_SECONDARY: (p.internalValue ? BUTTON_SECONDARY : BUTTON_PRIMARY)} 
                        title={p.answers[1].toUpperCase()} 
                        onPress={() => {
                            setInternalReset(false)
                            p.onResponse(false)
                        }}/>
                </View>
            </View>
            { p.messageAlert && <Text style={{color: RED}}>{p.messageAlert}</Text> }
            { p.hiddenLine || <LineView /> }
        </View>
    )
}



export default function QuestionMachine(props) {

    const { languages, code, metrics } = useContext(UserContext);
    const { machine } = props
    var textInputWidth = useRef(null)

    const [ resetState, setResetState ] = useState({
        isElettric: true,
        hasConvogliatore: true,
        isFila: true,
        isDistribution: true,
        isTardiva: true
    })

    const [isElettric, setIsElettric] = useState(props.elettric);
    const [hasBlade, setHasBlade] = useState(props.blade);
    const [responseFila, setResponseFila] = useState(false); //false è ogni altra, true è ogni fila
    const [responseDistribution, setResponseDistribution] = useState(false); //false è pieno campo, true è al centro della fila
    const [isTardiva, setIsTardiva] = useState(false);
    const [textW, setTextW] = useState('')
    const [forceVisibleDistributionQuestion, setForceVisibleDistributionQuestion] = useState(false)
    
    
    

    useEffect(() => {

        
        
        const questionVisible = [
            { key: 'isElettric', value: machine.has_isobus === 1 }, //sempre visibile la domanda sullo spandiconcime elettrico
            { key: 'hasConvogliatore', value: machine.has_blade }, //la macchinaha ha il convogliatore
            { key: 'isFila', value: machine.has_blade && hasBlade && !resetState['hasConvogliatore'] }, //la macchinaha ha il convogliatore e l utente ha risposto alla domanda con si
            { key: 'isDistribution', value:  machine.has_blade && !hasBlade && !resetState['hasConvogliatore']}, //la macchinaha ha il convogliatore e l utente ha risposto alla domanda con no
            { key: 'isTardiva', value: machine.has_tardivo_convogliatore === 1 && !hasBlade && !responseDistribution }, //la macchina ha has_tardivo_convogliatore, l'utente ha messo No alla domanda sul convogliatore, l'utente ha messo "pieno campo" --> allora mostro la domanda
        ]
        //array with question visible
        
        const optionalQuestionVisibleArray = questionVisible.filter( visible => visible['value'] === true)

        //find for each question visible if the state is setting
        const res = optionalQuestionVisibleArray.map( q => {
            return resetState[q.key] === false
        }).filter( k => k === true)

        

        //se ha risposto tardiva si allora deve inserire l altezza della pianta
        const secondCondition = isTardiva ? resetState['isTardiva'] === false && isTardiva && parseInt(textW) > 0 : true
        
        props.isValid(res.length === optionalQuestionVisibleArray.length && secondCondition)
        
    }, [resetState, isElettric, hasBlade, responseFila, responseDistribution, isTardiva, forceVisibleDistributionQuestion])

    

    const showTardivo =  (!machine.has_blade && machine.has_tardivo_convogliatore === 1) || (machine.has_tardivo_convogliatore === 1 && !hasBlade && !responseDistribution && !resetState['hasConvogliatore'] && !resetState['isDistribution'])

    
    return (
        <ScrollView> 
            <View style={{marginHorizontal: 10}}>
                { machine.has_isobus === 1 && <Question 
                    isReset={resetState['isElettric']}
                    internalValue={isElettric}
                    title={languages["spandiconcime_title"][code]}
                    question={languages["spandiconcime_message"][code]}
                    answers={[languages["si"][code], languages["no"][code]]} 
                    onResponse={(res) => {
                        const newResetState = Object.assign(resetState, {"isElettric": false})
                        setResetState(newResetState)
                        props.onSelectElettricOption(res)
                        setForceVisibleDistributionQuestion(!forceVisibleDistributionQuestion)
                        setIsElettric(res)
                    }}/> }
                { machine.has_blade && 
                    
                    <Question 
                        messageAlert={ hasBlade ? languages["warning_select_blade_mess"][code] : null}
                        isReset={resetState['hasConvogliatore']}
                        internalValue={hasBlade}
                        title={languages["convogliatore_title"][code]} 
                        question={languages["convogliatore_message"][code]} 
                        answers={[languages["si"][code], languages["no"][code]]} 
                        onResponse={(res) => {
                                const newResetState = Object.assign(resetState, {"hasConvogliatore": false})
                                setResetState(newResetState)
                                props.onSelectBladeOption(res)
                                setForceVisibleDistributionQuestion(!forceVisibleDistributionQuestion)
                                setHasBlade(res)
                        }}/> }
                {/* { hasBlade && <ResultNode notes={languages["warning_select_blade_mess"][code]}/>} */}
                { machine.has_blade && hasBlade && !resetState['hasConvogliatore'] &&
                    
                    <Question 
                        isReset={resetState['isFila']}
                        internalValue={responseFila}
                        title={languages["fila_title"][code]} 
                        question={languages["fila_message"][code]} 
                        answers={[languages["each_row"][code], languages["not_each_row"][code]]} 
                        onResponse={(res) => {
                            const newResetState = Object.assign(resetState, {"isFila": false})
                            setResetState(newResetState)
                            setForceVisibleDistributionQuestion(!forceVisibleDistributionQuestion)
                            props.onSelectEachRowOption(res ? 'each_row' : 'not_each_row')
                            setResponseFila(res)
                        }} /> 
                        
                    
                }
                { machine.has_blade && !hasBlade && !resetState['hasConvogliatore'] &&
                    <Question 
                        isReset={resetState['isDistribution']}
                        internalValue={responseDistribution}
                        title={languages["modality_distribution_title"][code]}
                        question={languages["modality_distribution_question"][code]}
                        answers={[languages["center_row"][code], languages["full_field"][code]]} 
                        onResponse={ res => {
                            const newResetState = Object.assign(resetState, {"isDistribution": false})
                            setResetState(newResetState)
                            props.onSelectDistributionOption(res ? 'center' : 'full')
                            setForceVisibleDistributionQuestion(!forceVisibleDistributionQuestion)
                            setResponseDistribution(res)
                        }}
                    />
                }
                {
                    showTardivo &&
                    <View>
                        <Question 
                            hiddenLine={true}
                            internalValue={isTardiva}
                            isReset={resetState['isTardiva']} 
                            title={"Pianta Tardiva"}
                            question={languages["pianta_tardiva_question"][code]}
                            answers={[languages["si"][code], languages["no"][code]]} 
                            onResponse={ res => {
                                const newResetState = Object.assign(resetState, {"isTardiva": false})
                                setResetState(newResetState)
                                props.onSelectIsTardiva(res)
                                setIsTardiva(res)
                                setForceVisibleDistributionQuestion(!forceVisibleDistributionQuestion)
                            }}
                        /> 
                        { isTardiva && <View> 
                            <Text style={MEDIUM_B}>{languages["height_pianta"][code] + ' (' + getUnity(metrics, DISTANCE) + ')'}</Text>
                            <CustomTextInput 
                                ref={(ref) => textInputWidth.current = ref} 
                                accept="int"
                                keyboardType="numbers-and-punctuation" 
                                returnKeyType='done'
                                style={{padding: 5, ...MEDIUM_B}} 
                                onChangeText={(t) => {
                                    setTextW(t)
                                    setForceVisibleDistributionQuestion(!forceVisibleDistributionQuestion)
                                    props.onHeightPianta(t)
                                }} 
                                text={String(textW)}
                                placeholder={getUnity(props.metrics, DISTANCE)}/>
                        </View> }
                    </View>
                }
                
                

                
            </View>
        </ScrollView>
    )
}
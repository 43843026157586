import React, { useState, useContext, useEffect } from "react";
import { View , Platform } from 'react-native'

//custom componets
import GreenLine from '../GreenLine'
import StartConfigurator from './StartConfigurator'
import Machines from "./Machines/Machines";
import QuestionMachine from './Machines/QuestionMachine'
import { NavigationProcess2 } from '../Need'
import { AlertAnimated, AlertForWeb } from '../General/CustomAlert'
import FertilizerFamilies from './Products/Families'
import AlertMessage from './Products/AlertMessage'
import Shapes from './Products/Shapes'
import KgLTSearch from './Products/KgLTSearch'
import SearchForSimilarity from "./Products/SearchForSimilarityContainer";
import Fertilizer from './Products/Fertilizer'
import SetupMachine from './Machines/SetupMachine'
import ValueGeneric from './Value/ValueGeneric'
import Result from "./Results/Result";
import Paginator from '../Need/Paginator'

import { UserContext } from '../../utils/context'

export default function Home(props) {

  const favorite = props.favorite
  const { languages, code, message, setMessage } = useContext(UserContext);

  

  const [favoriteInner, setFavoriteInner] = useState( props.favorite );

  const [start, setStart] = useState( favorite ? true : false);
  const [useBlade, setUseBlade] = useState( false );
  const [isTardiva, setIsTardiva] = useState( false );
  const [cmHeightPianta, setCmHeightPianta] = useState( 0 );
  const [elettric, setElettric] = useState( false );

  const [distributionField, setDistributionField] = useState( 'full' );
  const [eachRow, setEachRow] = useState( 'each_row' );

  const [machine, setMachine] = useState( favorite ? favorite.content.machine : null);
  const [setupMachine, setSetupMachine] = useState( favorite ? favorite.content.paletta : null );
  const [family, setFamily] = useState( favorite ? favorite.content.family : null );
  const [shape, setShape] = useState(null)
  const [kglt, setKglt] = useState("")
  const [distribution, setDistribution] = useState({d1: 0, d2: 0, d3: 0, d4: 0})
  const [fertilizer, setFertilizer] = useState( favorite ? favorite.content.product : null);
  const [settings, setSettings] = useState( favorite ? favorite.content.settings : null );
  const [searchSimilarity, setSearchSimilarity] = useState(false);
  const [isEnablePrev, setIsEnablePrev] = useState(true);
  const [isEnableNext, setIsEnableNext] = useState(false);
  const [indexActive, setIndexActive] = useState(0);

  const startComp = <StartConfigurator {...props} startConfigurator={() => setStart(!start)}/>

  const resetProcess = () => {
    setFavoriteInner(null)
    setMachine(null)
    setShape(null)
    setKglt("")
    setDistribution({d1: 0, d2: 0, d3: 0, d4: 0})
    setSetupMachine(null)
    setFamily(null)
    setFertilizer(null)
    setSettings(null)
    setSearchSimilarity(false)
    setIsEnableNext(false)
    setIndexActive(0)
    setUseBlade(false)
    setIsTardiva(false)
    setCmHeightPianta(0)
    setDistributionField('full')
    setEachRow('each_row')
  }

  const setFavoriteProcess = (f) => {
    
    //setStart(true)
    setMachine(f.machine)
    setSetupMachine(f.paletta)
    setFamily(f.family)
    setFertilizer(f.product)
    setSettings(f.settings)
    
    setSearchSimilarity(false)
    setIsEnableNext(true)
    setIndexActive(pages.length-1)
    setStart(true)
    setShape(null)
    setKglt("")
    setDistribution({d1: 0, d2: 0, d3: 0, d4: 0})
    setIsTardiva(false)
    setCmHeightPianta(0)
  }

  useEffect(() => {
    
    
    if (favoriteInner) {
      setStart(true)
      setFavoriteProcess(props.favorite.content)
      setFavoriteInner(null)
    } else {
      if (start) {
        setStart(!start)
        resetProcess()
      }
    }
    
    


  }, [props.favorite, props.restart])

  const pages = [
    {
      "component": <Machines {...props} 
                      machine={machine} 
                      onSelectMachine={(m) => setMachine(m)} 
                      isValid={(valid) => setIsEnableNext(valid)}/>,
      "title": languages["machine"][code],
      "subtitle": machine ? machine.name : '-'
    }, 
    {
      "component": machine ? ( <QuestionMachine {...props} 
        has_blade={machine.has_blade}
        blade={useBlade}
        elettric={elettric}
        machine={machine}
        eachRow={eachRow}
        distributionField={distributionField}
        onSelectIsTardiva={(is_tardiva) => setIsTardiva(is_tardiva)}
        onHeightPianta={(cmHeight) => setCmHeightPianta(cmHeight)}
        onSelectEachRowOption={(eachRowRes) => setEachRow(eachRowRes)}
        onSelectDistributionOption={(dist) => setDistributionField(dist)}
        onSelectElettricOption={(m) => setElettric(m)}
        onSelectBladeOption={(m) => setUseBlade(m)}
        isValid={(valid) => setIsEnableNext(valid)}/> ): null,
      "title": languages["machine"][code],
      "subtitle": machine ? machine.name : '-'
    },
    {
      "component": <FertilizerFamilies 
        idFamily={family ? family.id : -1} 
        isValid={(valid) => setIsEnableNext(valid)}
        {...props} 
        onSearchClick={() => {
          setIndexActive(indexActive+1)
          setSearchSimilarity(true) 
          setFamily(null)
        }}
        onSelectFamily={(f) => {
            setFamily(f)
            setSearchSimilarity(false) 
          }}/>,
      "title": languages["family"][code],
      "subtitle": family ? family.name : '-'
    },
    {
      "component": searchSimilarity ? 
                      <Shapes {...props} shape={shape}
                        onSelectShape={ s => setShape(s)}
                        isValid={(valid) => setIsEnableNext(valid)}/> : null,
      "title": languages["analisi_concime"][code],
      "subtitle": shape ? shape.name : '-'
    },
    {
      "component": searchSimilarity ? 
                      <KgLTSearch {...props} kglt={kglt}
                        onSelectKgLT={ kglt => setKglt(kglt)}
                        isValid={(valid) => setIsEnableNext(valid)}/> : null,
      "title": languages["analisi_concime"][code],
      "subtitle": languages["set_kglt"][code],
    },
    {
      "component": searchSimilarity ? 
                      <SearchForSimilarity {...props} 
                        distribution={distribution}
                        onSelectDistribution={ d => setDistribution(d)}
                        isValid={(valid) => setIsEnableNext(valid)}/> : null,
      "title": languages["analisi_concime"][code],
      "subtitle": '',
    },
    {
      "component": <Fertilizer {...props} 
        blade={useBlade}
        standardSearch={!searchSimilarity}
        isValid={(valid) => setIsEnableNext(valid)}
        idMachine={machine ? machine.id : -1}
        idFamily={family ? family.id : -1} 
        paramForSearch={{
          "kglt": kglt, 
          "selectedId": shape ? shape.id : -1, 
          "distribution": distribution,
          "idMachine": machine ? machine.id : -1}}
        fertilizer={fertilizer} 
        onSelectFertilizer={(f) => setFertilizer(f)}/>,
      "title": languages["product"][code],
      "subtitle": fertilizer ? fertilizer.name : '-'
    },
    {
      "component": <AlertMessage fertilizer={fertilizer} />,
      "title": languages["product"][code],
      "subtitle": fertilizer ? fertilizer.name : '-'
    }, 
    {
      "component": <SetupMachine {...props} 
        blade={useBlade}
        fertilizer={fertilizer}
        machine={machine}
        setupMachine={setupMachine}
        eachRow={eachRow}
        distributionRow={distributionField}
        onSelectSetup={(s) => setSetupMachine(s)}
        isValid={(valid) => setIsEnableNext(valid)}/>,
      "title": languages["setup_machine"][code]
    },
    {
      "component": <ValueGeneric {...props} 
        blade={useBlade}
        idMachine={machine ? machine.id : -1}
        idFertilizer={fertilizer ? fertilizer.id : -1} 
        isValid={(valid) => setIsEnableNext(valid)}
        paletteSelected={setupMachine}
        settings={settings}
        onSelectValue={(value) => setSettings(value)}/>,
      "title": languages["setting_manual"][code]
    },
    {
      "component": <Result {...props} 
          blade={useBlade}
          elettric={elettric}    
          machine={machine}
          fertilizer={fertilizer} 
          family={family} 
          settings={settings}
          paletta={setupMachine} 
          tardiva={isTardiva}
          cmHeightPianta={cmHeightPianta}/>,
      "title": languages["results"][code]
    }
  ].filter( p => p.component !== null)


  
  return (
    <View style={{flexDirection: 'column', flex: 1}}>
      { Platform.OS !== 'web' && <GreenLine /> }
      { start && 
         <NavigationProcess2
            start={start}
            isEnablePrev={isEnablePrev} 
            isEnableNext={isEnableNext}
            element={pages[indexActive]} 
            numberPage={pages.length}
            pageActive={indexActive} 
            homeClick={() => { 
              setStart(!start)
              resetProcess()
            }}
            onNext={(index) => setIndexActive(index+1)} 
            onPrev={(index) => setIndexActive(index-1)} 
          /> }
        { Platform.OS !== 'web' ? 
        (message &&  <AlertAnimated {...message} onFinish={() => setMessage(null)}/>) : 
        (message && <AlertForWeb {...message} onFinish={() => setMessage(null)}/>)}
        <View style={{flexDirection: 'column', flex: 1}}>
        <View style={{
            paddingHorizontal: (Platform.OS === 'web' ? '10%' : 0),
            paddingTop: 10 ,
            paddingBottom: Platform.OS === 'web' ? 100 : 10}}>
            { start ? pages[indexActive].component : startComp }
        </View>
      </View>
      <View style={{marginBottom: 1}}>
        { start && <Paginator onNext={(index) => setIndexActive(index+1)}
            numberPage={pages.length} pageActive={indexActive} isEnableNext={isEnableNext} /> }
        </View>
    </View>
  )

  
}


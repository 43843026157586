import { getUnity, AREA_TIME, converValue, VELOCITY, DISTANCE, QUANTITY_DISTANCE } from "../../../metrics";
import { formatNumber } from '../../../utils'
import { CustomIcon } from '../../General/CustomIcon'
import { DARK_GRAY } from "../../../style";

export const getDistribution = (dist) => {
    return dist.val_1 + "% - " + dist.val_2 + "% - " + dist.val_3 + "% - " + dist.val_4 + "%"
}

const GREEN_METEO = '#27ae60'
const RED_METEO = '#e74c3c'
const ORANGE_METEO = '#e67e22'
const NOT_DEFINED_METEO = '#7f8c8d'


export const getStatusForMeteo = (meteo, languages, code) => {

  

  if (meteo.weather.length === 0) {
    return { 
      i: "alert-outline", 
      bg:NOT_DEFINED_METEO, 
      msg:"Condiziona meteo non conosciuta" 
    } 
  }

  
  switch (meteo.weather[0].main) {
        case "Clouds":
            return { 
              i: "cloud-outline", 
              bg:GREEN_METEO, 
              msg: languages["good_condition_meteo"][code],
              score:1
            }
        case "Clear":
            return { 
              i: "sunny-outline", 
              bg:GREEN_METEO, 
              msg: languages["perfect_condition_meteo"][code],
              score:0.8
        } 
        case "Rain":
            return { 
              i: "rainy-outline", 
              bg:RED_METEO, 
              msg: languages["not_good_condition_meteo"][code],
              score:0.7
            }

        case "Foggy":
              return { 
                i: "rainy-outline", 
                bg:ORANGE_METEO, 
                msg: languages["not_good_condition_meteo"][code],
                score:0.5
              } 
        case "Snow":
                return { 
                  i: "snow-outline", 
                  bg: RED_METEO, 
                  msg: languages["not_good_condition_meteo"][code],
                  score:0.2
                } 
        default:
            return { 
              i: "alert-outline", 
              bg: NOT_DEFINED_METEO, 
              msg: "Condiziona meteo non conosciuta",
              score:0 
            } 
  }

}

export const section_input_data = (machine, family, fertilizer, settings, elettric, languages, code, metrics) => {
    

    return {
        "title":languages["input"][code], //input
        "rows": [
            { 
                titleRow: "machine",
                leftText: languages["machine"][code], 
                rightText: machine ? machine.name : '-',
            },
            { 
                titleRow: "family",
                leftText: languages["family"][code], 
                rightText: family ? family.name : '-'  },
            { 
                titleRow: "product",
                leftText: languages["product"][code], 
                rightText: fertilizer ? fertilizer.name : '-'  
            },
            { 
                titleRow: "shape",
                leftText: languages["shape"][code], 
                rightText: fertilizer ? fertilizer.shape : '-'  },
            { 
                titleRow: "kg_lt",
                leftText: "Kg/LT (toto)", 
                rightText: fertilizer ? fertilizer.kgl : '-', 
            },
            { 
                titleRow: "distribution",
                leftText: languages["distribution"][code], 
                rightText: fertilizer ? getDistribution(fertilizer.values) : '-' 
            },
            elettric ? null : { 
                titleRow: "width",
                leftText: languages["width"][code], 
                rightText: settings ? converValue(settings.width, metrics, DISTANCE) + " " +  getUnity(metrics, DISTANCE) : '-'
            },
            { 
                titleRow: "velocity",
                leftText: languages["velocity"][code], 
                rightText: settings ? converValue(settings.velocity, metrics, VELOCITY)+ " " + getUnity(metrics, VELOCITY): '-'},
            elettric ? null : { 
                titleRow: "quantity",
                leftText: languages["quantity"][code], 
                rightText: settings ? converValue(settings.quantity, metrics, QUANTITY_DISTANCE) + " " + getUnity(metrics, QUANTITY_DISTANCE): '-' 
            }
        ].filter( a => a !== null)
    }
}


export const section_surface = (out, languages, code, metrics) => {
    const v = out.hourlySurface ? out.hourlySurface : '-'
    return {
        "title": languages["target_flow"][code], 
        "rows": [
            {
                titleRow: "target_flow",  
                leftText: getUnity(metrics, AREA_TIME), 
                rightText: formatNumber(converValue(v, metrics, AREA_TIME ))
            }
        ].filter( a => a !== null)
    }
}


export const section_settings_machine = (out, isEletric = false, languages, code, has_convogliatore) => {
    
        
    const v = out && out.vanesset ? out.vanesset : (out.fallpoints ? out.fallpoints : null)
    const position = (out && out.position) ? out.position.output : null
    const message = out ? out.message : null

    
    
    return {
        "title":languages["set_output_title"][code], //set_output_title
        "rows": [
            {
                titleRow: "vanes_lenght", 
                leftText: languages["vanes_lenght"][code], 
                rightText: (v && v.vanesLenghtSet) || '-', 
                icona: require('../../../images/allung_paletta.png'), 
                iconaLarge: require('../../../images/paletta_large_bis.png'),
                visibility: !(out.hide_vanes === true)
                //youtube: youtube_link["distribution"]
            },
            { 
                titleRow: "vanes_position_title", 
                leftText: languages["vanes_position_title"][code], 
                rightText: (v && v.vanesSet) || '-', 
                icona: require('../../../images/orient_paletta.png'),
                iconaLarge: require('../../../images/orient_paletta_large.png'),
                visibility: !(out.hide_vanes === true)
                //youtube: youtube_link["distribution"]
            },
            { 
                titleRow: "drop_point", 
                leftText: languages["drop_point"][code], 
                rightText: (v && v.fallPointSet) || '-', 
                icona: require('../../../images/pointFall.png'),
                iconaLarge: require('../../../images/pointFall_large.png'),
                visibility: !(out.hide_vanes === true)

            },
            { 
                titleRow: "open_position", 
                leftText: languages["open_position"][code], 
                rightText: (position && position.position) || '-', 
                icona: require('../../../images/openPosition.png'),
                iconaLarge: require('../../../images/openPosition_large.png'),
                visibility: !isEletric
            },
            isEletric ? null : { 
                titleRow: "velocity_pto", 
                leftText: languages["velocity_pto"][code], 
                rightText: (v && v.pto) || '-',
                icona: require('../../../images/velocitaPTO.png'),
                iconaLarge: require('../../../images/velocitaPTO_large.png'),
                visibility: true
            },
            {
                titleRow: "height_a",  
                leftText: languages["height_a"][code], 
                rightText: (v && v.heightA) || '-', 
                icona: require('../../../images/heightA.png'),
                iconaLarge: require('../../../images/heightAB_large.png'),
                visibility: true
            },
            {
                titleRow: "height_b", 
                leftText: languages["height_b"][code], 
                rightText: (v && v.heightB) || '-', 
                icona: require('../../../images/heightA.png'),
                iconaLarge: require('../../../images/heightAB_large.png'),
                visibility: true,
                optionalMessage: null
            },
            {
                titleRow: "kg_min",  
                leftText: languages["kg_min"][code] + ' (' +languages["per_lato_message"][code] + ')', 
                rightText: (position && position.kgminute) || '-', 
                icona:require('../../../images/kgMin.png'),
                iconaLarge: require('../../../images/kgMin_large.png'),
                visibility: true,
                optionalMessage: message,
                actionView: true
            },
            {
                titleRow: "blade_regulator",  
                leftText: languages["blade_regulator"][code], 
                rightText: (v && v.bladePosition) || '-', 
                icona:require('../../../images/bladePosition.png'),
                iconaLarge: require('../../../images/bladePosition_large.png'),
                visibility: has_convogliatore 
            }
        ].filter( a => a !== null).filter( a => a.visibility )
    }
}


export const section_settings_isobus = (out, fertilizer, settings, elettric, languages, code, metrics) => {

        
        
    const v = out && out.vanesset ? out.vanesset : (out && out.fallpoints ? out.fallpoints : null)
    const vri_count = out && out.fallpoints?.fallPoints ? 
        out.fallpoints?.fallPoints.filter( el => el.fallPointPos !== "").length : 
        null
    
    return {
        "title": languages["set_isobus"][code], 
        "rows": [
            elettric ? { 
                titleRow: "product",
                leftText: languages["product"][code], 
                rightText: fertilizer ? fertilizer.name : '-'
            } : null,
            elettric ? { 
                titleRow: "width",
                leftText: languages["width"][code], 
                rightText: settings ? converValue(settings.width, metrics, DISTANCE) + " " +  getUnity(metrics, DISTANCE) : '-'
            } : null,
            elettric ? { 
                titleRow: "quantity",
                leftText: languages["quantity"][code], 
                rightText: settings ? converValue(settings.quantity, metrics, QUANTITY_DISTANCE) + " " + getUnity(metrics, QUANTITY_DISTANCE): '-' 
            } : null,
            elettric ? { 
                titleRow: "velocity_pto",
                leftText: languages["velocity_pto"][code], 
                rightText: (v && v.pto) || '-',
                icona: require('../../../images/velocitaPTO.png'),
                iconaLarge: require('../../../images/velocitaPTO_large.png'),
                visibility: true,
            } : null,
            {
                titleRow: "t_factor",
                leftText: languages["t_factor"][code], 
                rightText: out ? out.calibration_app : '-',  
            },
            vri_count ? {
                titleRow: "regulator_VRI", 
                leftText: languages["regulator_VRI"][code], 
                rightText: "( " + vri_count + " )", 
                isNavigation: true, 
                data: out.fallpoints?.fallPoints,
                accessoryView: <CustomIcon name="arrow-forward" size={20} color={DARK_GRAY} />
             } : null,
        ].filter( a => a !== null)
    }
}

export const section_bordura = (out, title, languages, code) => {
    const v_pto = out.pto
    const v_set = out.set

    
    return {
        "title": title,
        "title_segmented": [languages["settings"][code], languages["pto"][code]],
        "rows": [
            {
                titleRow: "marginal", 
                leftText: languages["marginal"][code], 
                rightText: [v_set?.marginal, v_pto?.marginal],  
                icona: require('../../../images/marginale.png'),
                iconaLarge: require('../../../images/marginale_large.png'),
                visibility: true
            },
            {
                titleRow: "boundary",
                leftText: languages["boundary"][code], 
                rightText: [v_set?.boundary, v_pto?.boundary], 
                icona: require('../../../images/confine.png'),
                iconaLarge: require('../../../images/confine_large.png'),
                visibility: true

            },
            {
                titleRow: "fossato", 
                leftText: languages["fossato"][code], 
                rightText: [v_set?.ditch, v_pto?.ditch], 
                icona: require('../../../images/fossato.png'),
                iconaLarge: require('../../../images/fossato_large.png'),
                visibility: true
            },
        ].filter( a => a !== null)
    }
}

export const section_flow_reduction = (out, languages, code) => {
    const v_comp = out.computer
    const v_mach = out.machine
    return {
        "title": languages["reduction"][code], //reduction
        "title_segmented": [languages["computer"][code], languages["mechanical"][code]],
        "rows": [
            {
                titleRow: "marginal_flow_reduction",  
                leftText: languages["marginal"][code], 
                rightText: [v_comp?.marginal, v_mach?.marginal],  
                icona: require('../../../images/marginale.png'),
                iconaLarge: require('../../../images/marginale_large.png'),
                visibility: true
            },
            {
                titleRow: "boundary_flow_reduction",   
                leftText: languages["boundary"][code], 
                rightText: [v_comp?.boundary, v_mach?.boundary], 
                icona: require('../../../images/confine.png'),
                iconaLarge: require('../../../images/confine_large.png'),
                visibility: true
            },
            {
                titleRow: "fossato_flow_reduction",
                leftText: languages["fossato"][code], 
                rightText: [v_comp?.ditch, v_mach?.ditch], 
                icona: require('../../../images/fossato.png'),
                iconaLarge: require('../../../images/fossato_large.png'),
                visibility: true
            },
        ].filter( a => a !== null)
    }
}

const acapo = "\n" //TODO a capo non funziona

export const generateTxtBordura = (objBorder) => {
        
    const title = objBorder.title + acapo
    const title_sub_1 = "\t" + objBorder.title_segmented[0] + ":"+acapo
    const title_sub_2 = "\t" + objBorder.title_segmented[1] + ":"+acapo
    var tripla_1 = ""
    var tripla_2 = ""
    objBorder.rows.forEach( r => {
        tripla_1 = tripla_1 + "\t\t" + r.leftText + ": " + r.rightText[0] + acapo
        tripla_2 = tripla_2 + "\t\t" + r.leftText + ": " + r.rightText[1] + acapo
    })
    return title + title_sub_1 + tripla_1 + title_sub_2 + tripla_2
}


export const generateTxt = (o) => {
    var body = ""
    o.rows.forEach( r => {
        body = body + "\t" + r.leftText + ": " + r.rightText + acapo
    })
    return o.title + acapo + body
}



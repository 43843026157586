import { StyleSheet, Platform, Dimensions } from "react-native";

//FONTS
export const DEFAULT_FONT = 'Arial'
export const DEFAULT_FONT_LIGHT = 'Arial'
export const DEFAULT_FONT_BOLD = 'Arial'


//COLORS
export const CLEAR = '#c0392b00'

export const SUCCESS = '#77A02E'
export const TICK_GREEN = '#50A76F'
export const WARNING = '#f1c40f'
export const WARNING_LIGHT = '#f6e58d'
export const ERROR = '#e74c3c'

export const GREEN = '#096141'
export const DARK_TAB = '#333'
export const GREEN_GRAY = '#CFE0D9'
export const LIGHT_GREEN = '#CFE0D9'
export const WHITE = '#FFFFFF'
export const BLACK = '#000000'
export const RED = '#c0392b'
export const GREEN2 = '#005a32'
export const GREEN3 = '#238b45'
export const RED_TRANSPARENT = '#c0392b44'

export const EXTRA_DARK_GRAY = "#2D3436" //extra gray (not used yet)
export const DARK_GRAY = '#2c3e50' //usato nelle icone piccole
export const LINK_COLOR = "#2980b9"
export const MEDIUM_GRAY = '#B2BEC3' //medium usato per ora solo nel need paginator
export const LIGHT_GRAY =  Platform.OS === 'web' ? '#F6F6F6' : '#DFDFDF' //light gray
export const YOUTUBERED = "#d60000"
export const DEFAULT_GRAY_MOBILE = "#F2F2F2"

export const GRAY_BG_TRANSPARENT = '#dcdcdcDD'
export const DARK_GRAY_TRANSPARENT = '#636E72DD'
export const DISABLE_GRAY = '#CBCBCB'

export const GREEN_ARRAY = ["#27AE60", "#218C74", "#38ADA9"]


export const windowWidth = Dimensions.get('window').width;
export const windowHeight = Dimensions.get('window').height;
export const DEFAULT_PAD = Platform.OS === 'web' ? 20 : 15 //space inside container default
export const DEFAULT_PAD_PERCENT = Platform.OS === 'web' ? '10%' : 0
export const SPAM = 4 //heigt of row that have one title
export const SQUARE_SIZE = 35 //heigt of row that have one title
export const CONTAINER_PAD = Platform.OS === 'web' ? 0 : 8
export const SPACE_BOTTOM = Platform.OS === 'web' ? 12 : 8


export const buttons = StyleSheet.create({
    select_button: {
        borderRadius: 30,
        padding: 5,
        backgroundColor: GREEN
    },
    unselect_button: {
        borderRadius: 30,
        borderColor: GREEN,
        borderWidth: 1,
        padding: 5,
        backgroundColor: LIGHT_GRAY
    },
    flat_button: {
        backgroundColor: 'red'
    }
})

export const decorate = StyleSheet.create({
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    }
})


export const container = StyleSheet.create({
    config_fow_web: { //box that contains config process 
        flex: 1, 
        paddingHorizontal: DEFAULT_PAD,
        paddingTop: Platform.OS === 'web' ? DEFAULT_PAD : 60,
        paddingBottom: Platform.OS === 'web' ? DEFAULT_PAD : 40,
        backgroundColor: WHITE,
        marginHorizontal: Platform.OS === 'web'? '10%' : 0,
        marginVertical: Platform.OS === 'web' ? '2%' : 0,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.45,
        shadowRadius: 10,
        elevation: 5
    },
    line: { //line
        borderBottomColor: DARK_GRAY,
        borderBottomWidth: 0.5,
        marginVertical: 10
    },
    underline_box: { //bordo verde a qualcosa
        borderWidth: 2,
        borderColor: GREEN,
        borderRadius: 4,
    },
    not_underline_box: { //stesso border radius ma senza bordo verde
        borderRadius: 4,
        borderColor: LIGHT_GRAY,
        borderWidth: 2,
    },
    modal_view: { //usata nelle modal
        backgroundColor: WHITE,
        borderRadius: 20,
        padding: 22,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: windowWidth < 500 ? windowWidth : windowWidth / (Platform.OS === 'web' ? 2 : 1 )
    },
    error_view: { //usata per quando ce un errore
        backgroundColor: RED_TRANSPARENT,
        borderRadius: 20,
        borderWidth: 1,
        borderColor: RED
    },
    search: { //use for display result
        backgroundColor: LIGHT_GRAY,
        borderRadius: 12,
        padding: 6
    },
    text_input: { //use for display result
        backgroundColor: LIGHT_GRAY,
        borderRadius: 12,
        paddingHorizontal: 12,
        paddingVertical: 10
    },
    row_result: { //usata nei risultati
        backgroundColor: WHITE,
        borderRadius: 5,
        paddingVertical: 3,
        paddingHorizontal: 12,
        borderColor: LIGHT_GRAY,
        borderWidth: 2
    },
    primay_button: { //usato qnd voglio un bottone verde con 
        borderRadius: 4,
        //height: 40,
        paddingHorizontal: 26,
        paddingVertical: 6,
        backgroundColor: GREEN,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },
    primay_button_red: { //usato qnd voglio un bottone verde con 
        borderRadius: 4,
        //height: 40,
        paddingHorizontal: 26,
        paddingVertical: 6,
        backgroundColor: RED,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },
    secondary_button: { //usato qnd voglio un bottone grigio
        borderRadius: 4,
        borderColor: GREEN,
        borderWidth: 1,
        paddingHorizontal: 26,
        paddingVertical: 6,
        backgroundColor: CLEAR, //or CLEAR
        justifyContent: 'center',
        marginBottom: 10
    },
    disable_button: { //usato qnd voglio un bottone disable
        borderRadius: 4,
        paddingHorizontal: 26,
        paddingVertical: 6,
        backgroundColor: DISABLE_GRAY,
        justifyContent: 'center',
        marginBottom: 10
    },
    flat_button: {
        marginBottom: 10
    },
    tab_active_button: {
        borderColor: CLEAR,
        borderWidth: 3,
        borderBottomColor: GREEN
    },
    tab_not_active_button: {
        //backgroundColor: DARK_GRAY
        borderColor: CLEAR,
        borderWidth: 3,
    },
    navigation_bar: {
        flexDirection:'row', 
        justifyContent:'space-between', 
        marginVertical: Platform.OS === 'web' ? 10 : 15,
        marginHorizontal: Platform.OS === 'web' ? '10%' : 15
    },
    generic_container: {
        paddingHorizontal: Platform.OS === 'web' ? '10%' : 15,
        paddingTop: 10,
        //paddingBottom: Platform.OS === 'web' ? 20 : 100,
        //marginTop: 20,
        //marginBottom: Platform.OS === 'web' ? 20 : 150,
    },
    card: {
        margin: 5,
        borderRadius: 14
        //...decorate.shadow
    },
    
    
})

export const getFont = (size = 12, color = BLACK, font = 'Arial') => {
    return { 
        color: color,
        fontFamily: font, 
        fontSize: size
    }
}

export const getSquareButton = (size = SQUARE_SIZE, is_active = true, is_disable = false) => {
    return { 
        width: size,
        height: size,
        borderRadius: size/2,
        justifyContent:'center', 
        alignItems:'center',
        backgroundColor: is_active ? (is_disable ? DISABLE_GRAY : GREEN) : WHITE,
        borderColor: GREEN,
        borderWidth: is_active ? 0 : 0.5,
        ...decorate.shadow
    }
}

export const TITLE_B = getFont(22, DARK_GRAY, DEFAULT_FONT)
export const MEDIUM_B = getFont(18, DARK_GRAY, DEFAULT_FONT)
export const TEXT_B = getFont(15, DARK_GRAY, DEFAULT_FONT_LIGHT)
export const TEXT_B_RED = getFont(15, RED, DEFAULT_FONT_LIGHT)

export const TITLE_W = getFont(22, WHITE, DEFAULT_FONT_BOLD)
export const MEDIUM_W = getFont(18, WHITE, DEFAULT_FONT_BOLD)
export const TEXT_W = getFont(13, WHITE, DEFAULT_FONT_LIGHT)

export const BUTTON_SQUARE_ACTIVE = getSquareButton(SQUARE_SIZE, true) //buttone quadrato verde
export const BUTTON_SQUARE_NOT_ACTIVE = getSquareButton(SQUARE_SIZE, false) //buttone quadrato bianco
export const BUTTON_SQUARE_DISABLE = getSquareButton(SQUARE_SIZE, true, true) //button quadrato disable



export const BUTTON_PRIMARY = 'BUTTON_PRIMARY' //buttone primary
export const BUTTON_PRIMARY_RED = 'BUTTON_PRIMARY_RED'
export const BUTTON_SECONDARY = 'BUTTON_SECONDARY' //buttone secondary
export const BUTTON_TAB_ACTIVE = 'BUTTON_TAB_ACTIVE' //usato per la tab active
export const BUTTON_TAB_NOT_ACTIVE = 'BUTTON_TAB_NOT_ACTIVE' //usato per la tab not active
export const BUTTON_FLAT = 'BUTTON_FLAT'
export const BUTTON_FLAT_GRAY = 'BUTTON_FLAT_GRAY' //solo scritta
export const BUTTON_FLAT_LINK = 'BUTTON_FLAT_LINK'



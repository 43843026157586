import React, { useContext } from "react";
import { View, Text, ScrollView, Pressable, Platform } from 'react-native'
import CountryFlag from "react-native-country-flag";
import { WHITE, DARK_GRAY, SQUARE_SIZE, getFont, BUTTON_SECONDARY, DEFAULT_FONT_LIGHT, DEFAULT_FONT } from '../../style'
import { UserContext } from '../../utils/context'
import { getMapLanguages } from '../../langs'
import { TextButton } from '../General/Other';
import User, { NotUserLogged } from './User/User'
import { logout } from '../../api/dcm_api'
import GreenLine from '../GreenLine'
import { CustomIcon } from "../General/CustomIcon";


export function RowSettings(props) {

    return (
        <>
        <Pressable onPress={props.onClickRow} style={{paddingVertical: 12}}>
            <View style={{flexDirection:'row', alignItems:'center', 
                justifyContent: 'space-between'}}>
                <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                { !props.isFlag && <View style={{justifyContent: 'center', width: 28, alignItems: 'center'}}>
                    { props.isText ?  
                        <Text style={getFont(15, DARK_GRAY, DEFAULT_FONT_LIGHT)}>{props.icon}</Text> :
                        <CustomIcon name={props.icon} size={20} color={DARK_GRAY } /> 
                    }
                </View> }
                { props.isFlag && <CountryFlag isoCode={props.icon} size={SQUARE_SIZE} style={{width: 28, height: 20, borderRadius: 3}} /> }
                { props.title &&<Text style={{...getFont(13, DARK_GRAY, DEFAULT_FONT), marginHorizontal: 12}}>
                    { props.title.toUpperCase() }
                </Text> }
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                    <Text style={{...getFont(16, DARK_GRAY, DEFAULT_FONT_LIGHT), marginRight: 5}}>
                        { props.detail.toUpperCase() }
                    </Text> 
                    {/* <CustomIcon name={'forward'} size={18} color={DARK_GRAY} style={{ justifyContent:'center'}}/> */}
                    <CustomIcon name={'arrow-forward'} color={DARK_GRAY} size={18}/>
                </View>
            </View>
        </Pressable>
        { props.last || <View style={{borderBottomColor: DARK_GRAY, borderBottomWidth: 0.9}}/> }
        </>
    )
}


export default function Settings(props) {

    const appcontext = useContext(UserContext);
    const favorite_len = String(appcontext.favoritesState.response.length)

    

    const logoutApi = () => {
        logout().then( _ => {
            appcontext.setUserLogged(null)
            appcontext.resetState()
        })
    }

    return (
        <View>
        { Platform.OS !== 'web' && <GreenLine /> }
        <ScrollView style={{paddingTop: Platform.OS === 'web' ? 0 : 30 }}>
            {/* { appcontext.message && <AlertAnimated {...appcontext.message} onFinish={() => appcontext.setMessage(null)}/> } */}
            { appcontext.user ? 
                <User user={appcontext.user}/> : 
                <NotUserLogged 
                    onLoginNow={(what) => {
                        props.navigation ? 
                            props.navigation.push('UserContainer', { what }) : 
                            props.onClickOnRow('UserContainer', { what})
                    }}/>
            }
             <View style={{backgroundColor: WHITE, paddingHorizontal: 15, marginBottom: 20}}>
                 <RowSettings 
                     title={appcontext.languages["about"][appcontext.code]}
                     icon="information" 
                     onClickRow={() => props.navigation ? props.navigation.push('ChiSiamo') : props.onClickOnRow('ChiSiamo') } 
                     detail=""/>
                <RowSettings title={appcontext.languages["change_lang"][appcontext.code]}
                    icon={getMapLanguages()[appcontext.code].flag} 
                    isFlag={true}
                    detail={getMapLanguages()[ appcontext.code].name}
                    onClickRow={() => props.navigation ? 
                        props.navigation.push('ChooseLang') : 
                        props.onClickOnRow('ChooseLang')} />
                <RowSettings title={ appcontext.languages["change_measurament"][appcontext.code]}  
                    icon={appcontext.metrics}
                    isText={true}
                    onClickRow={() => props.navigation ? 
                        props.navigation.push('ChooseMetrics') : 
                        props.onClickOnRow('ChooseMetrics')} 
                    detail={appcontext.metrics}/>
                <RowSettings title={appcontext.languages["history"][appcontext.code]} 
                    icon="star" 
                    onClickRow={() => props.navigation ? 
                        props.navigation.push('Favorites') : 
                        props.onClickOnRow('Favorites') }  
                    detail={favorite_len}/> 
                <RowSettings title={"Disclaimer"} 
                    icon="warning" last={true}
                    onClickRow={() => props.navigation ? 
                        props.navigation.push('Disclaimer') : 
                        props.onClickOnRow('Disclaimer') }  
                    detail={""}/> 
                
             </View>
             { appcontext.user && 
                <TextButton 
                    extra_style={{marginHorizontal: 10}}
                    type={BUTTON_SECONDARY} 
                    title="LOGOUT" onPress={() => logoutApi()}/> }
            <View style={{width: '100%', height: 100 }}/>
        </ScrollView>
        </View>
    )
}